import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/SomoS_Co_Logo-small3.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-scroll";
import translations from "./translations";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";

const Home = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"

    return (
        <div className="home-container" id="home">
            <Navbar />
            <br/><br/><br/><br/>
            <div className="home-banner-container fade-in">
                <div className="home-bannerImage-container">
                    <img src={BannerBackground} alt="SomoS-Co Strategy"/>
                </div>
                <div className="home-text-section">
                    <h1 className="primary-heading showColor">{t.home_heading}</h1>
                    <p className="primary-text">{t.home_text1}</p>
                    <p className="primary-text">{t.home_text2}</p>
                    <button className="secondary-button">
                        <Link to="about" smooth={true} duration={1000} offset={-100}>{t.home_button}</Link>
                        <FiArrowRight/>
                    </button>
                </div>
                <div className="home-image-section">
                    <img src={BannerImage} alt="SomoS-Co Consulting" style={{cursor: 'grab'}}/>
                </div>
            </div>
        </div>
    );
};

export default Home;
