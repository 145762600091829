import React, { useContext, useEffect, useState, useRef } from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import { LanguageContext } from "./LanguageContext";
import translations from "./translations";

const Testimonial = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div className="work-section-wrapper" id="testimonials">
            <div className="work-section-top">
                <p className="primary-subheading">{t.testimonials}</p>
                <h1 className="primary-heading heading-wide">{t.testimonials_heading}</h1>
                <p className="primary-text">{t.testimonials_text}</p>
            </div>
            <div ref={ref} className={`testimonial-section-bottom ${isVisible ? 'fade-in-up' : ''}`}>
                <img src={ProfilePic} alt="Client Testimonial"/>
                <p>"{t.testimonial}"</p>
                <div className="testimonials-stars-container">
                    <AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/>
                </div>
                <h2>{t.client}</h2>
            </div>
        </div>
    );
};

export default Testimonial;
