// translations.js
const translations = {
    en: {
        home: "Home",
        about: "About",
        work: "How We Work",
        hello: "Hello",
        team: "Team",
        testimonials: "Testimonials",
        contact: "Contact",
        signIn: "Sign in",
        aboutUs: "About Us",
        about_heading: "Empowering Businesses for Sustainable Growth",
        about_text1:"At Somos-Co GmbH, we specialize in turning business challenges into opportunities. Our expertise lies in strategic planning, resource management, and business forecasting to ensure your company's growth and success.",
        about_text2:"With a track record of delivering actionable insights and innovative solutions, we help our clients navigate complex market dynamics and achieve optimal performance.",
        about_button: "Learn More",
        home_heading: "Strategic Consulting for Tomorrow's Leaders",
        home_text1: "Somos-Co GmbH leads customer-specific programs in digitization, operational excellence, innovation, dynamic modeling, commercial and people excellence. Our responsibilities include facilitating strategic workshops, overseeing project execution, and providing coaching, training, and consulting services.",
        home_text2: "We specialize in designing, architecting, and implementing custom software solutions that support quality, supply chain, IT, sales, services and finance tools in transactional, business, and artificial intelligence domains. Partner with SomoS-Co to harness the power of strategic foresight and innovative solutions, propelling your business into a future of growth and success.",
        home_button: "Discover Our Services",
        work_heading: "Our Processes and Products",
        work_text: "Our proven products and processes ensures that we understand your business inside and out, enabling us to provide solutions that drive real results.",
        work_info1: "Initial Consultation",
        work_info2: "Customized Strategy Development",
        work_info3: "Identify Needs, Implement, Improve and Grow",
        work_info_text1: "Discover how our strategic consulting can address your unique business challenges during an initial consultation.",
        work_info_text2: "Customized Development with Training, Workshops, Consulting, Coaching, Dynamic Modeling, Process Mining, Prototyping and Interim Management.", // Work with our experts to develop a tailored strategy focused on achieving your business objectives.
        work_info_text3: "Implement your customized strategy and operations with our training, consulting, coaching, and quality, business, and IT tools to watch your business grow and succeed.", // See some customer examples
        testimonials_heading: "What Our Clients Say",
        testimonials_text: "Hear directly from the businesses that have transformed their operations and strategies with SomoS-Co's expert consulting services.",
        testimonial: "Partnering with SomoS-Co was a game-changer for our business. Their strategic insights and actionable solutions have driven our growth and competitiveness in the industry.",
        client: "John Doe, CEO of Tech Innovations",
        contact_keyword: "Optimize",
        contact_heading1: "Ready to ",
        contact_heading1_2: "Your Business?",
        contact_heading2: "Let's Get Started",
        contact_email: "yourmail@somos-co.com",
        contact_button: "Submit",
        team_subheading: "Meet the Team",
        team_heading: "Management",
        team_text: "Our team of experts is dedicated to helping your business succeed. With a wealth of experience and a passion for innovation, we are committed to delivering the best results for your company. We are partnering with other Consulting companies to provide always the best experts on the market",
        footer_text1: "About Us",
        footer_text2: "Our Services",
        footer_text3: "Contact Us",
        footer_text4: "Testimonials",
        footer_text5: "Privacy Policy",
        footer_text6: "Terms of Service",


    },
    de: {
        home: "Startseite",
        about: "Über Uns",
        work: "Wie Wir Arbeiten",
        hello: "Grüßi",
        team: "Team",
        testimonials: "Referenzen",
        contact: "Kontakt",
        signIn: "Anmelden",
        aboutUs: "Über Uns",
        about_heading: "Unternehmen nachhaltig stärken",
        about_text1:"Bei SomoS-Co spezialisieren wir uns darauf, geschäftliche Herausforderungen in Chancen zu verwandeln. Unsere Expertise liegt in der strategischen Planung, Ressourcenverwaltung und Geschäftsvorhersage, um das Wachstum Ihres Unternehmens zu sichern.",
        about_text2:"Mit einer Erfolgsbilanz von umsetzbaren Erkenntnissen und innovativen Lösungen helfen wir unseren Kunden, komplexe Marktdynamiken zu navigieren und optimale Leistungen zu erzielen.",
        about_button: "Erfahren Sie Mehr",
        home_heading: "Strategische Beratung für die Führungskräfte von Morgen",
        home_text1: "Die Somos-Co GmbH führt kundenspezifische Programme in den Bereichen Digitalisierung, Betriebs-, Innovations-, Handels- und Personalexzellenz. Zu den Aufgaben gehören die Durchführung strategischer Workshops, die Überwachung der Projektausführung sowie die Bereitstellung von Coaching-, Schulungs- und Beratungsdiensten. Spezialisierte Programme werden durch die kundenspezifische Gestaltung, Architektur und Implementierung von Softwarelösungen unterstützt.",
        home_text2: "Arbeiten Sie mit SomoS-Co zusammen, um die Kraft der strategischen Planung und innovativer Lösungen zu nutzen und Ihr Unternehmen in eine Zukunft des Wachstums und Erfolgs zu führen.",
        home_button: "Entdecken Sie Unsere Dienstleistungen",
        work_heading: "Unser Prozess",
        work_text: "Unser bewährter Prozess stellt sicher, dass wir Ihr Geschäft durch und durch verstehen, um Lösungen bereitzustellen, die echte Ergebnisse erzielen.",
        work_info1: "Erstberatung",
        work_info2: "Entwicklung Maßgeschneiderter Strategien",
        work_info3: "Umsetzung & Wachstum",
        work_info_text1: "Entdecken Sie, wie unsere strategische Beratung Ihre einzigartigen Geschäftsherausforderungen während einer Erstberatung adressieren kann.",
        work_info_text2: "Entwickeln Sie mit unseren Experten eine auf Ihre Geschäftsziele ausgerichtete maßgeschneiderte Strategie.",
        work_info_text3: "Setzen Sie Ihre maßgeschneiderte Strategie mit unserer Unterstützung um und beobachten Sie das Wachstum und den Erfolg Ihres Unternehmens.",
        testimonials_heading: "Was Unsere Kunden Sagen",
        testimonials_text: "Hören Sie direkt von den Unternehmen, die ihre Operationen und Strategien mit den Expertenberatungen von SomoS-Co transformiert haben.",
        testimonial: "Die Partnerschaft mit SomoS-Co war ein Wendepunkt für unser Geschäft. Ihre strategischen Einblicke und umsetzbaren Lösungen haben unser Wachstum und unsere Wettbewerbsfähigkeit in der Branche vorangetrieben.",
        client: "John Doe, CEO von Tech Innovations",
        contact_keyword: "optimieren",
        contact_heading1: "Bereit, ",
        contact_heading1_2: "Ihr Geschäft zu ",
        contact_heading2: "Lassen Sie uns Anfangen",
        contact_email: "ihremail@somos-co.com",
        contact_button: "Senden",
        team_subheading: "Treffen Sie das Team",
        team_heading: "Unsere Experten",
        team_text: "Unser Expertenteam ist darauf ausgerichtet, Ihrem Unternehmen zum Erfolg zu verhelfen. Mit einer Fülle von Erfahrungen und einer Leidenschaft für Innovation verpflichten wir uns, die besten Ergebnisse für Ihr Unternehmen zu liefern. Wir arbeiten mit anderen Beratungsunternehmen zusammen, um stets die besten Experten auf dem Markt bereitzustellen.",
        footer_text1: "Über Uns",
        footer_text2: "Dienstleistungen",
        footer_text3: "Kontakt",
        footer_text4: "Referenzen",
        footer_text5: "Datenschutzbestimmungen",
        footer_text6: "Nutzungsbedingungen",
    },
    hu: {
        home: "Kezdőlap",
        about: "Rólunk",
        work: "Munkamódszerünk",
        hello: "Üdv",
        team: "Csapatunk",
        testimonials: "Vélemények",
        contact: "Kapcsolat",
        signIn: "Bejelentkezés",
        aboutUs: "Rólunk",
        about_heading: "Vállalkozások erősítése a fenntartható növekedés érdekében",
        about_text1:"A SomoS-Co-nál arra specializálódtunk, hogy üzleti kihívásokat lehetőségekké alakítsunk. Szakértelmünk a stratégiai tervezésben, erőforrás-menedzsmentben és üzleti előrejelzésben rejlik, hogy biztosítsuk vállalata növekedését és sikerét.",
        about_text2:"A megvalósítható betekintések és innovatív megoldások nyújtásának bizonyított rekordjával segítünk ügyfeleinknek a bonyolult piaci dinamikák navigálásában és optimális teljesítmény elérésében.",
        about_button: "Tudjon Meg Többet",
        home_heading: "Stratégiai Tanácsadás a Holnap Vezetőiknek",
        home_text1: "A Somos-Co GmbH vezet ügyfél-specifikus programokat a digitalizáció, az üzemeltetés, az innováció, a kereskedelem és a személyzet kiválósága terén. A feladatok közé tartozik a stratégiai workshopok szervezése, a projekt végrehajtásának felügyelete, valamint coaching, képzési és tanácsadási szolgáltatások nyújtása. A speciális programokat az egyedi tervezés, architektúra és szoftvermegoldások megvalósítása támogatja.",
        home_text2: "Kössön partnerséget a SomoS-Co-val a stratégiai tervezés és az innovatív megoldások erejének kiaknázására, amely előre mozdítja vállalkozását a növekedés és siker jövője felé.",
        home_button: "Fedezze fel Szolgáltatásainkat",
        work_heading: "Munkafolyamatunk",
        work_text: "Bizonyított folyamatunk biztosítja, hogy belülről és kívülről megismerjük vállalkozását, lehetővé téve számunkra, hogy valós eredményeket hozó megoldásokat nyújtsunk.",
        work_info1: "Kezdeti Konzultáció",
        work_info2: "Személyre Szabott Stratégia Kialakítása",
        work_info3: "Végrehajtás és Növekedés",
        work_info_text1: "Fedezze fel, hogyan címezhetjük egyedi üzleti kihívásait stratégiai tanácsadásunkkal egy kezdeti konzultáció során.",
        work_info_text2: "Dolgozzon együtt szakértőinkkel, hogy kifejlesszen egy üzleti céljaira szabott stratégiát.",
        work_info_text3: "Valósítsa meg személyre szabott stratégiáját támogatásunkkal, és nézze meg, ahogy vállalkozása növekszik és prosperál.",
        testimonials_heading: "Mit Mondanak Ügyfeleink",
        testimonials_text: "Hallgassa meg közvetlenül azon vállalkozásokat, amelyek megváltoztatták működésüket és stratégiáikat a SomoS-Co szakértői tanácsadásával.",
        testimonial: "A SomoS-Co-val való partnerség vállalkozásunk számára fordulópontot jelentett. Stratégiai betekintéseik és cselekvésre késztető megoldásaik előre mozdították növekedésünket és versenyképességünket az iparágban.",
        client: "John Doe, a Tech Innovations vezérigazgatója",
        contact_keyword: "optimalizálására",
        contact_heading1: "Készen áll ",
        contact_heading1_2: "üzletének az ",
        contact_heading2: "Kezdjük el",
        contact_email: "emailje@somos-co.com",
        contact_button: "Küldés",
        team_subheading: "Ismerje meg a Csapatot",
        team_heading: "Szakértőink",
        team_text: "Szakértői csapatunk elkötelezett amellett, hogy vállalkozása sikerét segítse. Széleskörű tapasztalattal és innováció iránti szenvedéllyel köteleztük el magunkat vállalata legjobb eredményeinek elérésére. Más tanácsadó cégekkel működünk együtt, hogy mindig a legjobb szakértőket biztosítsuk a piacon.",
        footer_text1: "Rólunk",
        footer_text2: "Szolgáltatásaink",
        footer_text3: "Kapcsolatbalépés",
        footer_text4: "Vélemények",
        footer_text5: "Adatvédelmi Irányelvek",
        footer_text6: "Szolgáltatási Feltételek",
    }
};

export default translations;
